import React, {useState, useEffect, useRef} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';

import Dashboard from "./Views/Dashboard";
import Signup from "./Views/Signup";
import Login from "./Views/Login";

import './App.css';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.js';
import 'jquery/dist/jquery.min.js';
import 'popper.js/dist/popper.min.js';

function App() {

  let item = localStorage.getItem("admin");

  return (
    <Router>
      <Switch>
        <Route exact path="/dashboard">
          {item ? <Dashboard/> : <Redirect to="/"/>}
        </Route>
        <Route exact path="/signup">
          {!item ? <Signup/> : <Redirect to="/dashboard"/>}
        </Route>
        <Route exact path="/">
          {!item ? <Login/> : <Redirect to="/dashboard"/>}
        </Route>
        <Route path="/*">
          <Redirect to="/"/>
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
