import {useState, useEffect, useRef} from "react";
import {Link, useHistory} from "react-router-dom";
import "../css/signup.css"
import * as ReactBootstrap from "react-bootstrap";

function Signup() {
  let history = useHistory();

  useEffect(() => {
  }, [])

  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <ReactBootstrap.Col className="signup">
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <h1 className="heading-h1">SIGN UP</h1>
                <hr/>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                <input type="text" placeholder="First Name"/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                <input type="text" placeholder="Last Name"/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                <input type="text" placeholder="Email"/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                <input type="password" placeholder="Password"/>
              </ReactBootstrap.Col>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                <input type="password" placeholder="Confirm Password"/>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
            <ReactBootstrap.Row>
              <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <Link to="/">
                  <input className="signup-btn" type="button" value="Sign Up"/>
                </Link>
              </ReactBootstrap.Col>
            </ReactBootstrap.Row>
          </ReactBootstrap.Col>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  )
}

export default Signup;
