import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import * as ReactBootstrap from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import Cesium, {
  Ion,
  createWorldTerrain,
  Cartesian3,
  Cartesian2,
  JulianDate,
  SampledPositionProperty,
  Color,
  HeightReference,
  IonResource,
  TimeIntervalCollection,
  DistanceDisplayCondition,
  VelocityOrientationProperty,
  TimeInterval,
  createOsmBuildings,
  Viewer,
  GeoJsonDataSource,
  defined,
  Cartographic,
  Math,
  Clock,
  scene
} from "cesium";

// import railwayRoad from "../assets/geojson/europe-rail-road.geojson"
import cologneBonnRoute from "../assets/geojson/Cologne-Bonn_Route.json"

import "../css/dashboard.css";
import "cesium/Build/Cesium/Widgets/widgets.css";

import trainImage from "../assets/images/train-icon.png";
import locationImage from "../assets/images/location.png"
import logo from "../assets/images/WNGS-logo_white_RGB.png"

function Dashboard() {
  const viewerRef = useRef()
  const detailsRef = useRef()
  const [start, setStart] = useState(JulianDate.fromIso8601("2020-03-09T23:10:00Z"))
  const [Locomative, setLocomative] = useState("DBahn")
  const [startLocation, setStartLocation] = useState("Bonn")
  const [stopLocation, setStopLocation] = useState("Köln")
  const [currentLocation, setCurrentLocation] = useState()
  const [temperature, setTemperature] = useState("21 °C")
  const [timerLocation, setTimerLocation] = useState(0)
  const [timerLocationLast, setTimerLocationLast] = useState(cologneBonnRoute.length)
  const [eventIndex, setEventIndex] = useState(0);
  const [showGeoData, setShowGeoData] = useState([])
  const [newGeoData, setNewGeoData] = useState([])
  const [trainClicked, setTrainClicked] = useState(false)
  const [sensorClicked, setSensorClicked] = useState(false)
  const [sensorPosition, setSensorPosition] = useState([])
  const [waggons, setWaggons] = useState([
    {
      "Name": "Wagon 01",
      "Owner": "Bayer",
      "Start": "Koblenz",
      "End": "Düsseldorf",
      "Goods": "CH4",
      "Type": "Chemical"
    },
    {
      "Name": "Wagon 02",
      "Owner": "Saturn",
      "Start": "Main",
      "End": "Düsseldorf",
      "Goods": "PS4",
      "Type": "Electronic"
    }
  ])
  let history = useHistory();
  const ACC_TOKEN = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI4N2NlMmI4ZS0yNjc5LTQ1N2ItYTcwNC1jNWM5NjFiMDM3NzkiLCJpZCI6NTcyMzYsImlhdCI6MTYyNDYwOTMxMX0.zeHW-u36iMnAU7G5VfQXUip2BHrLC0kgfvli4TK3ZSs"
  Ion.defaultAccessToken = ACC_TOKEN;

  const timeStepInSeconds = 60;
  const totalSeconds = timeStepInSeconds * (cologneBonnRoute.length - 1);

  const positionProperty = new SampledPositionProperty();
  const positionProperty2 = new SampledPositionProperty();
  const positionProperty3 = new SampledPositionProperty();

  const logoutUser = () => {
    localStorage.removeItem("admin");
    window.location.replace("/");
  }

  useEffect(async () => {
    const systemData = await axios.get("https://backend.rail-integration.nuromedia.com/");

    setShowGeoData(systemData.data);

    let newArr = [];

    systemData.data.forEach(function (obj, index) {
      for (let key in obj) {
        newArr.push({ "key": key, "value": obj[key] });
      }
    });

    setNewGeoData(newArr);

  }, [])

  useEffect(() => {
    const intervalLatLon = setInterval(() => {
      if (timerLocation == timerLocationLast - 1) {
        setTimerLocationLast(cologneBonnRoute.length)
        setTimerLocation(cologneBonnRoute.length - 1)
        clearInterval(intervalLatLon);
      } else if (timerLocation <= timerLocationLast - 2) {
        setTimerLocationLast(cologneBonnRoute.length)
        setTimerLocation(timerLocation + 1)
        clearInterval(intervalLatLon);
      } else {
        setTimerLocation(timerLocation + 1)
      }
    }, 5000)
  })

  useEffect(() => {
    const trainCGNBON = document.querySelector("#map-container .cesium-credit-lightbox");
  })

  let viewer = null;

  const handleClickedTrain = () => {
    if (trainClicked) {
      return (
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="big-text">
            <div>Locomative</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>Owner:</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>{Locomative}</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>Current Location:</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div id="coords">
              {
                timerLocation <= timerLocationLast
                  ?
                  cologneBonnRoute[timerLocation].Latitude + ", " + cologneBonnRoute[timerLocation].Longitude
                  :
                  cologneBonnRoute[timerLocationLast + 1].Latitude + ", " + cologneBonnRoute[timerLocationLast + 1].Longitude
              }
            </div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>Start:</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>{startLocation}</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>Stop:</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>{stopLocation}</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>Temperature:</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>{temperature}</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <ReactBootstrap.Row id="waggons-data">
              {
                waggons.map((wagonData, index) => {
                  return (
                    <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                      <ReactBootstrap.Row>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="big-text">
                          {wagonData.Name}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          Owner:
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          {wagonData.Owner}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          Start:
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          {wagonData.Start}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          End:
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          {wagonData.End}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          Goods:
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          {wagonData.Goods}
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          Type:
                        </ReactBootstrap.Col>
                        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
                          {wagonData.Type}
                        </ReactBootstrap.Col>
                      </ReactBootstrap.Row>
                    </ReactBootstrap.Col>
                  )
                })
              }
            </ReactBootstrap.Row>
          </ReactBootstrap.Col>
          {/*<ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>Geo data:</div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} id="external-geo-data">
            <ReactBootstrap.Row id="geo-coordination">
            {
              newGeoData.map((data, index) => {
                return (
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} key={index}>
                  <ReactBootstrap.Row>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {data.key}:
                  </ReactBootstrap.Col>
                  <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  {data.value}
                  </ReactBootstrap.Col>
                  </ReactBootstrap.Row>
                  </ReactBootstrap.Col>
                  )
                })
              }
              </ReactBootstrap.Row>
              </ReactBootstrap.Col>
            */}
        </ReactBootstrap.Row>
      )
    }
    if(sensorClicked){
      return(
        <ReactBootstrap.Row>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="big-text">
            <div>Sensor</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div>Current Location:</div>
          </ReactBootstrap.Col>
          <ReactBootstrap.Col xs={12} sm={12} md={12} lg={6} xl={6}>
            <div id="coords">
              {
                sensorPosition.latitude + ", " + sensorPosition.longitude
              }
            </div>
          </ReactBootstrap.Col>
        </ReactBootstrap.Row>
      )
    }
  }

  useEffect(() => {
    const stop = JulianDate.addSeconds(start, totalSeconds, new JulianDate());
    viewer = new Viewer(viewerRef.current, {
      terrainProvider: createWorldTerrain({
        // requestWaterMask: true
      }),
      baseLayerPicker: false,
      sceneModePicker: false,
      navigationHelpButton: false,
      fullscreenButton: false,
      infoBox: false,
      timeline: false,
      animation: false,
      clock: new Clock({
        startTime: start.clone(),
        stopTime: stop.clone(),
        currentTime: start.clone(),
        // zoomTo(start, stop);
        // clockStep: ClockStep.SYSTEM_CLOCK;
        multiplier: 10,
        shouldAnimate: true,
        canAnimate: true,
      })
    })

    let initialized = false;
    viewer.scene.globe.tileLoadProgressEvent.addEventListener(() => {
      if (!initialized && viewer.scene.globe.tilesLoaded === true) {
        viewer.clock.shouldAnimate = true;
        initialized = true;
        document.querySelector("#loading").classList.toggle('disappear', true)
      }
    });
    viewer.camera.flyTo({
      destination: Cartesian3.fromDegrees(12.02884, 50.43167, 2000000.0),
    });
    viewer.homeButton.viewModel.command.beforeExecute.addEventListener(
      function (e) {
        e.cancel = true;
        viewer.scene.camera.flyTo({ destination: Cartesian3.fromDegrees(12.02884, 50.43167, 2000000.0) });
      });
    viewer.scene.primitives.add(createOsmBuildings());

    viewer.clock.startTime = start.clone();
    viewer.clock.stopTime = stop.clone();
    viewer.clock.currentTime = start.clone();
    // viewer.timeline.zoomTo(start, stop);
    // viewer.clock.clockStep = ClockStep.SYSTEM_CLOCK;
    viewer.clock.multiplier = 10;
    viewer.clock.shouldAnimate = true;
    viewer.clock.canAnimate = true;

    viewer.selectedEntityChanged.addEventListener(function (selectedEntity) {
      if (defined(selectedEntity)) {
        detailsRef.current.classList.toggle("details_section_open")
        switch (selectedEntity.id) {
          case "Train":
            setTrainClicked(true)
            setSensorClicked(false)
            break;

          case "Sensor":
            setSensorClicked(true)
            setTrainClicked(false)
            break;
          default:
            return
        }
        // var cartographic = Cartographic.fromCartesian(selectedEntity.position)
        // var long = Math.toDegrees(cartographic.longitude).toFixed(2);
        // var lat = Math.toDegrees(cartographic.latitude).toFixed(2);
        // var heightString = cartographic.height.toFixed(2);
        // console.log(long, lat)
      } else {
        detailsRef.current.classList.toggle("details_section_open")
        setTrainClicked(false)
        setSensorClicked(false)
      }
    });
    for (let i in cologneBonnRoute) {
      const dataPoint = cologneBonnRoute[i];

      const time = JulianDate.addSeconds(start, i * timeStepInSeconds, new JulianDate());
      const position = Cartesian3.fromDegrees(dataPoint.Longitude, dataPoint.Latitude, 100);
      //setCurrentLocation(position);
      //currentPosition = position
      positionProperty.addSample(time, position)
    }

    // for (let i in routeData) {
    //     const dataPoint = routeData[i];

    //     const time = JulianDate.addSeconds(start, i * timeStepInSeconds, new JulianDate());
    //     const routePosition = Cartesian3.fromDegrees(dataPoint.Longitude, dataPoint.Latitude);
    //     positionProperty2.addSample(time, routePosition)
    // }

    let billboard = {
      image: trainImage,
      scale: 0.09,
      distanceDisplayCondition: new DistanceDisplayCondition(1200),
    }

    IonResource.fromAssetId(580577)
      .then(satelliteModel => {
        const ISSEntity = viewer.entities.add({

          model: {
            uri: satelliteModel,
            scale: 0.3,
            color: Color.CADETBLUE,
            heightReference: HeightReference.CLAMP_TO_GROUND
          },
          orientation: new VelocityOrientationProperty(positionProperty3),
          billboard: { ...billboard, heightReference: HeightReference.CLAMP_TO_GROUND, }
        });
        /*let totalTimeSeconds = 60 * 60 * 6;
        let timeStepInSeconds = 10;*/
        //         setInterval(async () => {
        //             let res = await fetch("https://api.wheretheiss.at/v1/satellites/25544");
        //             let data = await res.json();
        //             for (let index = 0; index < totalTimeSeconds; index += timeStepInSeconds) {
        //                 let position = Cartesian3.fromDegrees(data.latitude, data.longitude, data.altitude * 1000);
        //                 const time = JulianDate.addSeconds(start, index, new JulianDate());
        //                 positionProperty3.addSample(time, position)
        //             }
        //         }, 1000)
        // setInterval(async () => {
        //     let res = await fetch("https://backend.rail-integration.nuromedia.com/");
        //     let data = await res.json();
        //     let location ;
        //     function hex_to_ascii(str1) {
        //         var hex = str1.toString();
        //         var str = '';
        //         for (var n = 0; n < hex.length; n += 2) {
        //             str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
        //         }
        //         location = str.split(";");
        //         return str;
        //     }
        //     hex_to_ascii(data[0].data)
        //     for (let index = 0; index < totalTimeSeconds; index += timeStepInSeconds) {
        //         let latitude = data.iridium_latitude;
        //         let longitude = data.iridium_longitude;
        //         if (location === undefined) {
        //             latitude = 0;
        //             longitude = 0;
        //         }
        //         let position = Cartesian3.fromDegrees(Number(location[1]), Number(location[0]));
        //         const time = JulianDate.addSeconds(start, index, new JulianDate());
        //         positionProperty3.addSample(time, position)
        //     }
        // }, 1000)
        // ISSEntity.position = positionProperty3
        // viewer.trackedEntity = ISSEntity;
      });
    IonResource.fromAssetId(508300)
      .then(trainModel => {
        let model = {
          uri: trainModel,
          heightReference: HeightReference.CLAMP_TO_GROUND,
          scale: 0.3,
          color: Color.CADETBLUE
        };
        const trainEntity = viewer.entities.add({
          position: positionProperty,
          id: "Train",
          model,
          orientation: new VelocityOrientationProperty(positionProperty),
          billboard: { ...billboard, heightReference: HeightReference.CLAMP_TO_GROUND, },
          description:
            "<h2>Train travels from Bonn to Cologne</h2>",
        });

        /*let handler = new Cesium.ScreenSpaceEventHandler(viewer.scene.canvas);
        handler.setInputAction(function (movement) {
          let pickedObject = viewer.scene.pick(movement.endPosition);
          if (Cesium.defined(pickedObject)) {
            trainEntity.billboard.scale = 2.0;
          } else {
            trainEntity.billboard.scale = 1.0;
          }
        }, Cesium.ScreenSpaceEventType.LEFT_CLICK);*/

        const entity = viewer.entities.add({
          // model: { uri: trainModel, scale: 0.3, color: Color.CADETBLUE, heightReference: HeightReference.CLAMP_TO_GROUND },
          orientation: new VelocityOrientationProperty(positionProperty3),
          id: "Sensor",
          billboard: {
            image: locationImage,
            scale: 0.09,
            distanceDisplayCondition: new DistanceDisplayCondition(50),
            heightReference: HeightReference.CLAMP_TO_GROUND,
          },
        });

        let totalTimeSeconds = 60 * 60 * 6;
        let timeStepInSeconds = 5;
        //         setInterval(async () => {
        //             let res = await fetch("https://api.wheretheiss.at/v1/satellites/25544");
        //             let data = await res.json();
        //             for (let index = 0; index < totalTimeSeconds; index += timeStepInSeconds) {
        //                 let position = Cartesian3.fromDegrees(data.latitude, data.longitude, data.altitude * 1000);
        //                 const time = JulianDate.addSeconds(start, index, new JulianDate());
        //                 positionProperty3.addSample(time, position)
        //             }
        //         }, 1000)
        setInterval(async () => {
          let res = await fetch("https://backend.rail-integration.nuromedia.com/");
          let data = await res.json();
          let location;

          function hex_to_ascii(str1) {
            var hex = str1.toString();
            var str = '';
            for (var n = 0; n < hex.length; n += 2) {
              str += String.fromCharCode(parseInt(hex.substr(n, 2), 16));
            }
            location = str.split(";");
            setSensorPosition({latitude: Number(location[0].slice(0, -4)), longitude: Number(location[1].slice(0, -4))})
            return str;
          }

          hex_to_ascii(data[0].data)
          for (let index = 0; index < totalTimeSeconds; index += timeStepInSeconds) {
            let latitude = data.iridium_latitude;
            let longitude = data.iridium_longitude;
            if (location === undefined) {
              latitude = 0;
              longitude = 0;
            }
            let position = Cartesian3.fromDegrees(Number(location[1]), Number(location[0]));
            const time = JulianDate.addSeconds(start, index, new JulianDate());
            //setCurrentLocation(position)
            //console.log("X:" + position.x + "\n" + "Y:" + position.y + "\n" + "Z:" + position.z)
            //console.log(position)
            //setStartLocation("Cologne Central Station")
            //setStopLocation("Bonn Central Station")

            positionProperty3.addSample(time, position)
          }
          // var oldPositionCartographics = Cartographic.fromCartesian(positionProperty);
          // console.log(Math.toDegrees(oldPositionCartographics.longitude))
          //console.log(trainEntity)
        }, 1000)
        entity.position = positionProperty3

        // let geoFile = GeoJsonDataSource.load(railwayRoad);
        // geoFile.then((data) => {
        //     console.log(data.features)
        // })
      })

  }, [viewer])

  return (
    <ReactBootstrap.Container fluid={true} className="main-block" id="dashboard">
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="dashboard">
          <ReactBootstrap.Row className="wngs_header">
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="logo">
                <img className="logo_img" src={logo} alt="WNGS-logo" />
              </div>
            </ReactBootstrap.Col>
            <ReactBootstrap.Col xs={6} sm={6} md={6} lg={6} xl={6}>
              <div className="account">
                <div>
                  <div className="searchbar"></div>
                  <div className="account_link" onClick={logoutUser}>Logout</div>
                </div>
              </div>
            </ReactBootstrap.Col>
          </ReactBootstrap.Row>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
      <ReactBootstrap.Row className="main-info-block" ref={detailsRef}>
        <ReactBootstrap.Col md={4} lg={4} xl={4} className="d-none d-sm-none d-md-block details-section">
          {handleClickedTrain()}
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} md={8} lg={8} xl={8}>
          <div className="map-container">
            <div id="loading">
              <h1>
                <div className="lds-default">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </h1>
            </div>
            <div id="cesiumContainer" ref={viewerRef}>
            </div>
            <div id="animationContainer"></div>
          </div>
        </ReactBootstrap.Col>
        <ReactBootstrap.Col xs={12} sm={12} className="d-block d-sm-block d-md-none">
          {handleClickedTrain()}
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  )
};

export default Dashboard;
