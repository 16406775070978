import {useState, useEffect, useRef} from "react";
import {Link, useHistory} from "react-router-dom";
import * as ReactBootstrap from 'react-bootstrap';

import "../css/login.css"

function Login() {
  let history = useHistory()

  useEffect(() => {
  }, [])

  return (
    <ReactBootstrap.Container fluid={true}>
      <ReactBootstrap.Row>
        <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="login">
            <form name="login-form">
              <div>
                <h1 className="heading-h1">SIGN IN</h1>
                <hr/>
              </div>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                  <input type="text" placeholder="Username" id="username-login" name="email" required/>
                  {/* <label className="input_label">Email...</label> */}
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="input_form">
                  <input type="password" placeholder="Password" id="password-login" name="pass" required/>
                  {/* <label className="input_label">Passowrd...</label> */}
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row>
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12} className="text-center">
                  <div className="error-hide" id="login-error">
                    Username and/or password is wrong.
                  </div>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row className="forgot-password">
                <ReactBootstrap.Col xs={12} sm={12} md={12} lg={12} xl={12}>
                  <span className="login-span">Forgot Password?</span>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
              <ReactBootstrap.Row className="button-block" id="button-submit">
                <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <input className="login-btn" type="submit" onClick={e => {
                    e.preventDefault();
                    const email = document.forms["login-form"]["email"].value;
                    const pass = document.forms["login-form"]["pass"].value;
                    if (email == "admin" && pass == "admin") {
                      localStorage.setItem("admin", "Logged in as admin");
                      window.location.replace("/dashboard");

                      document.getElementById("username-login").classList.remove("error");
                      document.getElementById("password-login").classList.remove("error");
                      document.getElementById("login-error").classList.add("error-hide");
                      document.getElementById("login-error").classList.remove("error-show");
                    } else {
                      document.getElementById("username-login").classList.add("error");
                      document.getElementById("password-login").classList.add("error");
                      document.getElementById("login-error").classList.remove("error-hide");
                      document.getElementById("login-error").classList.add("error-show");
                    }
                  }} value="Sign in"/>
                </ReactBootstrap.Col>
                <ReactBootstrap.Col xs={12} sm={12} md={6} lg={6} xl={6}>
                  <Link to="/signup">
                    <input className="login-btn" type="button" value="Sign up"/>
                  </Link>
                </ReactBootstrap.Col>
              </ReactBootstrap.Row>
            </form>
          </div>
        </ReactBootstrap.Col>
      </ReactBootstrap.Row>
    </ReactBootstrap.Container>
  )
}

export default Login;
